<!--  -->
<template>
  <div style="height:100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },

  components: {},

  methods: {}
}

</script>
<style lang='less' scoped>
</style>
